@import '../../mixins';

.elite-menu {
  --menu-offset: 0;
  --elite-menu-height: #{rem(504)};
  --elite-menu-max-height: 70vh;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: rem(-10);

  width: 100%;

  opacity: 0;
  visibility: hidden;

  pointer-events: none;

  transition: margin-top var(--animation-timing) var(--cubic-bezier),
    opacity var(--animation-timing) var(--cubic-bezier),
    visibility var(--animation-timing) var(--cubic-bezier);

  @include mediaBigDesktop {
    --elite-menu-height: big(504);

    margin-top: big(-10);
  }

  @include mediaTablet {
    display: none;
  }

  &_show {
    margin-top: 0;

    opacity: 1;
    visibility: visible;

    & .elite-menu {
      &__wrapper {
        box-shadow: var(--shadow-large);
      }
    }
  }

  &__container {
    width: 100%;
    height: var(--elite-menu-height);
    max-height: var(--elite-menu-max-height);
    display: grid;
    grid-template-columns: var(--menu-offset) repeat(10, 1fr) var(--menu-offset);
    // column-gap: var(--grid-column-gap);
    // row-gap: var(--grid-row-gap);
  }

  &__wrapper {
    grid-column: 2/12;

    width: 100%;
    height: var(--elite-menu-height);
    max-height: var(--elite-menu-max-height);
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    column-gap: var(--grid-column-gap);
    row-gap: var(--grid-row-gap);

    background-color: var(--bg-white);

    transition: box-shadow var(--animation-timing-long) var(--cubic-bezier);

    pointer-events: all;
  }

  &__column {
    height: var(--elite-menu-height);
    max-height: var(--elite-menu-max-height);

    background-color: var(--bg-white);
    overflow: auto;
    overscroll-behavior: contain;

    @include scrollbar();

    &_categories {
      grid-column: 1/3;
      padding: rem(10);

      @include mediaBigDesktop {
        padding: big(10);
      }

      @include mediaLaptop {
        min-width: rem(220);
      }
    }

    &_all {
      grid-column: 3/9;
      padding: rem(25);
      padding-left: rem(5);

      @include mediaBigDesktop {
        padding: big(25);
        padding-left: big(5);
      }
    }
  }

  &__image {
    grid-column: 9/11;
    padding: rem(25);
    padding-left: rem(5);

    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    transition: opacity var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      padding: big(25);
      padding-left: big(5);
    }

    @include mediaLaptop {
      padding: rem(20);
      padding-left: 0;

      min-width: rem(220);
    }

    & > a {
      position: relative;

      width: 100%;
      height: 100%;
      display: block;
    }

    & img,
    & picture {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;

      user-select: none;
      pointer-events: none;
    }
  }

  &__tab {
    display: block;
    columns: 2;
    column-gap: rem(25);

    @include mediaBigDesktop {
      column-gap: big(25);
    }
  }

  &__subcategory {
    margin-bottom: rem(25);

    display: flex;
    flex-direction: column;
    gap: rem(10);
    page-break-inside: avoid;

    @include mediaBigDesktop {
      margin-bottom: big(25);

      gap: big(10);
    }

    @include mediaLaptop {
      gap: rem(5);
    }

    & .elite-menu {
      &__list {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        gap: rem(10);

        @include mediaBigDesktop {
          gap: big(10);
        }
      }
    }
  }

  &__name {
    color: var(--text-dark-primary);
    font-weight: 600;
    text-decoration: none;
  }

  &__link {
    position: relative;

    width: 100%;
    display: flex;

    color: var(--text-dark-secondary);
    text-decoration: none;

    transition: color var(--animation-timing) var(--cubic-bezier);

    @include hover {
      color: var(--accent-color);
    }

    &::before {
      @include pseudo(5) {
        @include mediaLaptop {
          top: rem(-2.5);
          left: rem(-2.5);
          width: calc(100% + rem(2.5) * 2);
          height: calc(100% + rem(2.5) * 2);
        }
      }
    }
  }

  &__list {
    padding: 0;
    margin: 0;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    list-style: none;
  }

  &__item {
    width: 100%;
  }

  &__category {
    padding: rem(10) rem(15);

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: rem(10);

    color: var(--text-dark-primary);
    text-decoration: none;

    transition: color var(--animation-timing) var(--cubic-bezier),
      background-color var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      padding: big(10) big(15);

      gap: big(10);
    }

    @include mediaLaptop {
      padding: rem(11.5) rem(15);
    }

    @include hover {
      color: var(--accent-color);

      & .elite-menu {
        &__icon {
          fill: var(--accent-color);
        }
      }
    }

    &.active {
      color: var(--accent-color);

      background-color: var(--bg-light);

      & .elite-menu {
        &__icon {
          fill: var(--accent-color);
        }
      }
    }
  }

  &__icon {
    width: rem(16);
    height: rem(16);
    display: flex;
    flex-shrink: 0;

    fill: var(--elements-gray);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(16);
      height: big(16);
    }
  }

  &-tab {
    display: none;

    &_active {
      display: block;
    }
  }
}

@import "elite-menu-card";
