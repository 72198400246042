.elite-menu-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(25);


  @include mediaBigDesktop {
    gap: big(25);
  }

  &__list {
    width: 100%;

    & .swiper {
      &-slide {
        width: 100%;
      }
    }
  }

  &__navigation {
    margin-top: auto;

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: rem(5);

    @include mediaBigDesktop {
      gap: big(5);
    }
  }

  & .product-card {
    width: 100%;
    max-width: unset;

    & > form {
      padding: 0;
      gap: rem(10);

      border: none;

      @include mediaBigDesktop {
        gap: big(10);
      }
    }

    &__top {
      height: rem(150);

      @include mediaDesktop {
        height: rem(200);
      }

      @include mediaBigDesktop {
        height: big(200);
      }

      @include mediaLaptop {
        height: rem(130);
      }
    }

    &__info {
      gap: rem(7);

      @include mediaBigDesktop {
        gap: big(7);
      }
    }

    &__bottom {
      padding: 0 rem(5);
      gap: rem(15);

      @include mediaBigDesktop {
        padding: 0 big(5);
        gap: big(15);
      }
    }
  }
}
