.elite-menu {
  --menu-offset: 0;
  --elite-menu-height: 31.5rem;
  --elite-menu-max-height: 70vh;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: -0.625rem;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: margin-top var(--animation-timing) var(--cubic-bezier), opacity var(--animation-timing) var(--cubic-bezier), visibility var(--animation-timing) var(--cubic-bezier);
  -o-transition: margin-top var(--animation-timing) var(--cubic-bezier), opacity var(--animation-timing) var(--cubic-bezier), visibility var(--animation-timing) var(--cubic-bezier);
  transition: margin-top var(--animation-timing) var(--cubic-bezier), opacity var(--animation-timing) var(--cubic-bezier), visibility var(--animation-timing) var(--cubic-bezier); }
  @media screen and (min-width: 160.0625rem) {
    .elite-menu {
      --elite-menu-height: big(504);
      margin-top: -0.39062vw; } }
  @media screen and (max-width: 87.5rem) {
    .elite-menu {
      display: none; } }
  .elite-menu_show {
    margin-top: 0;
    opacity: 1;
    visibility: visible; }
    .elite-menu_show .elite-menu__wrapper {
      -webkit-box-shadow: var(--shadow-large);
              box-shadow: var(--shadow-large); }
  .elite-menu__container {
    width: 100%;
    height: var(--elite-menu-height);
    max-height: var(--elite-menu-max-height);
    display: grid;
    grid-template-columns: var(--menu-offset) repeat(10, 1fr) var(--menu-offset); }
  .elite-menu__wrapper {
    grid-column: 2/12;
    width: 100%;
    height: var(--elite-menu-height);
    max-height: var(--elite-menu-max-height);
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    -webkit-column-gap: var(--grid-column-gap);
       -moz-column-gap: var(--grid-column-gap);
            column-gap: var(--grid-column-gap);
    row-gap: var(--grid-row-gap);
    background-color: var(--bg-white);
    -webkit-transition: -webkit-box-shadow var(--animation-timing-long) var(--cubic-bezier);
    transition: -webkit-box-shadow var(--animation-timing-long) var(--cubic-bezier);
    -o-transition: box-shadow var(--animation-timing-long) var(--cubic-bezier);
    transition: box-shadow var(--animation-timing-long) var(--cubic-bezier);
    transition: box-shadow var(--animation-timing-long) var(--cubic-bezier), -webkit-box-shadow var(--animation-timing-long) var(--cubic-bezier);
    pointer-events: all; }
  .elite-menu__column {
    height: var(--elite-menu-height);
    max-height: var(--elite-menu-max-height);
    background-color: var(--bg-white);
    overflow: auto;
    -ms-scroll-chaining: none;
        overscroll-behavior: contain; }
    .elite-menu__column::-webkit-scrollbar {
      width: 0.625rem; }
      @media screen and (min-width: 160.0625rem) {
        .elite-menu__column::-webkit-scrollbar {
          width: 0.39062vw; } }
    .elite-menu__column::-webkit-scrollbar-track {
      background: var(--bg-white); }
    .elite-menu__column::-webkit-scrollbar-thumb {
      background-color: var(--elements-gray-secondary);
      border-radius: 62.4375rem;
      border: 3px solid var(--bg-white); }
      @media screen and (min-width: 160.0625rem) {
        .elite-menu__column::-webkit-scrollbar-thumb {
          border-radius: 39.02344vw;
          border-width: 0.11719vw; } }
    .elite-menu__column_categories {
      grid-column: 1/3;
      padding: 0.625rem; }
      @media screen and (min-width: 160.0625rem) {
        .elite-menu__column_categories {
          padding: 0.39062vw; } }
      @media screen and (max-width: 109.375rem) {
        .elite-menu__column_categories {
          min-width: 13.75rem; } }
    .elite-menu__column_all {
      grid-column: 3/9;
      padding: 1.5625rem;
      padding-left: 0.3125rem; }
      @media screen and (min-width: 160.0625rem) {
        .elite-menu__column_all {
          padding: 0.97656vw;
          padding-left: 0.19531vw; } }
  .elite-menu__image {
    grid-column: 9/11;
    padding: 1.5625rem;
    padding-left: 0.3125rem;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: stretch;
        -ms-flex-pack: stretch;
            justify-content: stretch;
    -webkit-transition: opacity var(--animation-timing) var(--cubic-bezier);
    -o-transition: opacity var(--animation-timing) var(--cubic-bezier);
    transition: opacity var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .elite-menu__image {
        padding: 0.97656vw;
        padding-left: 0.19531vw; } }
    @media screen and (max-width: 109.375rem) {
      .elite-menu__image {
        padding: 1.25rem;
        padding-left: 0;
        min-width: 13.75rem; } }
    .elite-menu__image > a {
      position: relative;
      width: 100%;
      height: 100%;
      display: block; }
    .elite-menu__image img,
    .elite-menu__image picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: center;
         object-position: center;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      pointer-events: none; }
  .elite-menu__tab {
    display: block;
    -webkit-columns: 2;
       -moz-columns: 2;
            columns: 2;
    -webkit-column-gap: 1.5625rem;
       -moz-column-gap: 1.5625rem;
            column-gap: 1.5625rem; }
    @media screen and (min-width: 160.0625rem) {
      .elite-menu__tab {
        -webkit-column-gap: 0.97656vw;
           -moz-column-gap: 0.97656vw;
                column-gap: 0.97656vw; } }
  .elite-menu__subcategory {
    margin-bottom: 1.5625rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0.625rem;
    page-break-inside: avoid; }
    @media screen and (min-width: 160.0625rem) {
      .elite-menu__subcategory {
        margin-bottom: 0.97656vw;
        gap: 0.39062vw; } }
    @media screen and (max-width: 109.375rem) {
      .elite-menu__subcategory {
        gap: 0.3125rem; } }
    .elite-menu__subcategory .elite-menu__list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: start;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: start;
      gap: 0.625rem; }
      @media screen and (min-width: 160.0625rem) {
        .elite-menu__subcategory .elite-menu__list {
          gap: 0.39062vw; } }
  .elite-menu__name {
    color: var(--text-dark-primary);
    font-weight: 600;
    text-decoration: none; }
  .elite-menu__link {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: var(--text-dark-secondary);
    text-decoration: none;
    -webkit-transition: color var(--animation-timing) var(--cubic-bezier);
    -o-transition: color var(--animation-timing) var(--cubic-bezier);
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media (any-hover: hover) {
      .elite-menu__link:hover {
        color: var(--accent-color); } }
    .elite-menu__link::before {
      content: "";
      position: absolute;
      top: -0.3125rem;
      left: -0.3125rem;
      width: calc(100% + 0.3125rem * 2);
      height: calc(100% + 0.3125rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .elite-menu__link::before {
          top: -0.19531vw;
          left: -0.19531vw;
          width: calc(100% + 0.19531vw * 2);
          height: calc(100% + 0.19531vw * 2); } }
      @media screen and (max-width: 109.375rem) {
        .elite-menu__link::before {
          top: -0.15625rem;
          left: -0.15625rem;
          width: calc(100% + rem(2.5) * 2);
          height: calc(100% + rem(2.5) * 2); } }
  .elite-menu__list {
    padding: 0;
    margin: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    list-style: none; }
  .elite-menu__item {
    width: 100%; }
  .elite-menu__category {
    padding: 0.625rem 0.9375rem;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 0.625rem;
    color: var(--text-dark-primary);
    text-decoration: none;
    -webkit-transition: color var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier);
    -o-transition: color var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier);
    transition: color var(--animation-timing) var(--cubic-bezier), background-color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .elite-menu__category {
        padding: 0.39062vw 0.58594vw;
        gap: 0.39062vw; } }
    @media screen and (max-width: 109.375rem) {
      .elite-menu__category {
        padding: 0.71875rem 0.9375rem; } }
    @media (any-hover: hover) {
      .elite-menu__category:hover {
        color: var(--accent-color); }
        .elite-menu__category:hover .elite-menu__icon {
          fill: var(--accent-color); } }
    .elite-menu__category.active {
      color: var(--accent-color);
      background-color: var(--bg-light); }
      .elite-menu__category.active .elite-menu__icon {
        fill: var(--accent-color); }
  .elite-menu__icon {
    width: 1rem;
    height: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    fill: var(--elements-gray);
    -webkit-transition: fill var(--animation-timing) var(--cubic-bezier);
    -o-transition: fill var(--animation-timing) var(--cubic-bezier);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .elite-menu__icon {
        width: 0.625vw;
        height: 0.625vw; } }
  .elite-menu-tab {
    display: none; }
    .elite-menu-tab_active {
      display: block; }

.elite-menu-card {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 1.5625rem; }
  @media screen and (min-width: 160.0625rem) {
    .elite-menu-card {
      gap: 0.97656vw; } }
  .elite-menu-card__list {
    width: 100%; }
    .elite-menu-card__list .swiper-slide {
      width: 100%; }
  .elite-menu-card__navigation {
    margin-top: auto;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .elite-menu-card__navigation {
        gap: 0.19531vw; } }
  .elite-menu-card .product-card {
    width: 100%;
    max-width: unset; }
    .elite-menu-card .product-card > form {
      padding: 0;
      gap: 0.625rem;
      border: none; }
      @media screen and (min-width: 160.0625rem) {
        .elite-menu-card .product-card > form {
          gap: 0.39062vw; } }
    .elite-menu-card .product-card__top {
      height: 9.375rem; }
      @media screen and (min-width: 131.25rem) {
        .elite-menu-card .product-card__top {
          height: 12.5rem; } }
      @media screen and (min-width: 160.0625rem) {
        .elite-menu-card .product-card__top {
          height: 7.8125vw; } }
      @media screen and (max-width: 109.375rem) {
        .elite-menu-card .product-card__top {
          height: 8.125rem; } }
    .elite-menu-card .product-card__info {
      gap: 0.4375rem; }
      @media screen and (min-width: 160.0625rem) {
        .elite-menu-card .product-card__info {
          gap: 0.27344vw; } }
    .elite-menu-card .product-card__bottom {
      padding: 0 0.3125rem;
      gap: 0.9375rem; }
      @media screen and (min-width: 160.0625rem) {
        .elite-menu-card .product-card__bottom {
          padding: 0 0.19531vw;
          gap: 0.58594vw; } }
